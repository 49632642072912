import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
import { MobNav } from './MobNavD';
import { navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean; homepage: boolean }>`
    ${px};
    padding-top: 8px;
    padding-bottom: 8px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    background-color: ${({ homepage }) => (homepage ? 'transparent' : theme.colors.blue[950])};

    > div {
        ${flexSpace};
        max-width: 1772px;
        margin: 0 auto;

        > div {
            ${flexCenter};
            justify-content: flex-end;
        }
    }

    @media (min-width: 1320px) {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.blue[950]} !important;
        `};
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1319px) {
        > .gatsby-image-wrapper {
            width: 169px;
            height: 48px;
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1320px) {
        display: block;
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }

        :nth-last-of-type(2) {
            margin-right: auto;

            > a {
                margin-right: 0;
            }
        }
    }
`;

const subMenuStyles = css`
    display: block;

    li {
        line-height: 150%;
        position: static !important;
    }
`;

const subContentStyles = css`
    top: unset !important;
    left: 100%;
    z-index: 101;
    margin-top: calc(
        -1.62rem - 16px
    ); // minus the font size and vertical padding of sub nav btn to align submenu

    > ul {
        overflow-y: auto;
        border-radius: 16px;
        background-color: ${theme.colors.white};
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15);
        margin: 0;
    }
`;

const contentStyles = css`
    > ul {
        border-radius: 16px;
        background-color: ${theme.colors.white};
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15);
    }
`;

const PhoneBtn = styled(CLink)`
    ${flexCenter};
    margin-left: auto;
    margin-right: 16px;
    padding: 12px;
    border-radius: 12px;
    flex-shrink: 0;

    > svg {
        path {
            fill: ${({ theme }) => theme.colors.yellow[600]};
            transition: fill 0.3s ease-in-out;
        }
    }

    @media (min-width: 1320px) {
        margin-right: 0;
        margin-left: 32px;
        background-color: ${({ theme }) => theme.colors.gray[50]};
        transition: background-color 0.3s ease-in-out;
        box-shadow: 0px 8px 16px -8px #7d857a;

        :hover {
            background-color: ${({ theme }) => theme.colors.yellow[500]};
            box-shadow: 0px 8px 16px -8px #9e7e2e;

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.yellow[900]};
                }
            }
        }

        :focus-visible {
            background-color: ${({ theme }) => theme.colors.yellow[500]};
            box-shadow: 0px 8px 16px -8px #9e7e2e;

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.yellow[900]};
                }
            }
        }

        :active {
            background-color: ${({ theme }) => theme.colors.yellow[600]};
            box-shadow: none;

            > svg {
                path {
                    fill: ${({ theme }) => theme.colors.gray[50]};
                }
            }
        }
    }
`;

export const HomeLogo = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/logo.png"
            alt="raines over the rockies"
            width={225}
            height={64}
            loading="eager"
        />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const Nav = () => {
    const [allDialogsClosed, setAllDialogsClosed] = useState(false);
    const closeAllDialogs = () => setAllDialogsClosed(open => !open);

    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    const { title } = useLocationData();

    return (
        <Fragment>
            <Navbar scrolled={scrolled} homepage={title === ''}>
                <div>
                    <HomeLogo />
                    <DesktopNavMenu
                        menuStyles={menuStyles}
                        contentStyles={contentStyles}
                        subMenuStyles={subMenuStyles}
                        subContentStyles={subContentStyles}
                        links={navLinks}
                    >
                        <NavItem>
                            <PrimaryBtn as={CLink} lightYellow sm to={siteInfo.bookingLink}>
                                BOOK NOW
                            </PrimaryBtn>
                        </NavItem>
                    </DesktopNavMenu>
                    <PhoneBtn to={siteInfo.phone.link}>
                        <PhoneIcon />
                    </PhoneBtn>

                    <MobNav allDialogsClosed={allDialogsClosed} closeAllDialogs={closeAllDialogs} />
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
